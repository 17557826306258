import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import './index.css';

const mainHotels = [
  {
    name: 'The White Hart Inn',
    address: ['Inns Ln', 'Moorwood Moor Ln', 'Alfreton', 'DE55 7NU'],
    outside: '../../assets/images/whiteHart/reception.png',
    inside: '../../assets/images/whiteHart/double.png',
  },
  {
    name: 'The Horse & Jockey',
    address: ['6 Brackenfield Ln', 'Wessington', 'Alfreton', 'DE55 6DW'],
    outside: '../../assets/images/horseAndJockey/external.jpg',
    inside: '../../assets/images/whiteHart/room.png',
  },
];

const otherHotels = [
  {
    name: 'Critch Lane Farm',
    url: 'http://www.crichlanefarm.co.uk',
    address: ['Moorwood Moor Lane', 'Wessington', 'Alfreton', 'DE55 6DU'],
    distance: '0.9',
    duration: '3',
  },
  {
    name: 'Alfreton Travelodge',
    url: 'https://www.travelodge.co.uk/hotels/2/Alfreton-hotel',
    address: ['Old Swanwick', 'Colliery Road', 'Alfreton', 'DE55 1HJ'],
    distance: '3.7',
    duration: '10',
  },
  {
    name: 'Ripley Premier Inn',
    url: 'https://www.premierinn.com/gb/en/hotels/england/derbyshire/ripley/ripley.html',
    address: ['Nottingham Road', 'Ripley', 'Derby', 'DE5 3QP'],
    distance: '6.8',
    duration: '14',
  },
];

const AccommodationPage = () => {
  return (
    <Layout>
      <Seo title="Accommodation" />
      <div className="content">
        <section id="intro">
          <h1>Accommodation</h1>
          <br />

          <p>
            For guests staying at The White Hart Inn or The Horse & Jockey,
            please find information regarding your stay below. If you have any
            additional questions please drop us a message or feel free to ring
            the hotel directly. If you're still in need of a hotel or require a
            taxi on the night, please find some recommendations below.
          </p>
        </section>

        <hr />

        <section id="white-hart">
          <h2>Guests of The White Hart Inn</h2>
          <p style={{ paddingBottom: 0 }}>
            Check-in is from 2pm on Saturday, but keys can be collected and bags
            dropped off from 11am if required. If you believe you may need early
            check-in (allowing check-in from 12pm instead) please contact us
            directly. Check-out is at 11am on the Sunday.
          </p>
          <p>
            Room costs are to be paid upon arrival and the prices are as
            follows:
          </p>
          <ul>
            <li>Double room - £110</li>
            <li>Twin room - £110</li>
            <li>Family room - £125</li>
            <li>Superior room - £135</li>
          </ul>
          <p>
            Breakfast is served in the restaurant between 9am & 10am. Breakfast
            is included with the cost of the room.
          </p>

          <div className="HotelGrid">
            <StaticImage
              width={600}
              height={338}
              placeholder="dominantColor"
              src="../../assets/images/whiteHart/reception.png"
              alt={'A standard double bed room'}
            />
            <div className="OtherHotel">
              <h3>{mainHotels[0].name}</h3>
              <p>
                {mainHotels[0].address.map((line, i) => (
                  <>
                    {i > 0 ? <br /> : null}
                    {line}
                  </>
                ))}
              </p>
            </div>
            <StaticImage
              width={600}
              height={338}
              placeholder="dominantColor"
              src="../../assets/images/whiteHart/double.jpg"
              alt={'A standard double bed room'}
            />
          </div>
        </section>
        <hr />
        <section id="main-hotels">
          <h2>Guests of The Horse & Jockey</h2>
          <p style={{ paddingBottom: 0 }}>
            Check-in is from 2pm on Saturday, but keys can be collected and bags
            dropped off from 11am if required. Payment for the room is required
            on check-in. If you believe you may need early check-in (allowing
            check-in from 1pm instead) please contact us directly. Check-out is
            at 11am on the Sunday.
          </p>
          <p>
            Room costs are to be paid upon arrival and the prices are as
            follows:
          </p>
          <ul>
            <li>Double room - £110</li>
            <li>Twin room - £110</li>
            <li>Family room - £125</li>
            <li>Superior room - £135</li>
          </ul>
          <p>
            Breakfast is served in the restaurant between 9am & 10am. Breakfast
            is included with the cost of the room.
          </p>

          <div className="HotelGrid">
            <StaticImage
              width={600}
              height={338}
              placeholder="dominantColor"
              src="../../assets/images/horseAndJockey/external.jpg"
              alt={'A standard double bed room'}
            />
            <div className="OtherHotel">
              <h3>{mainHotels[1].name}</h3>
              <p>
                {mainHotels[1].address.map((line, i) => (
                  <>
                    {i > 0 ? <br /> : null}
                    {line}
                  </>
                ))}
              </p>
            </div>
            <StaticImage
              width={600}
              height={338}
              placeholder="dominantColor"
              src="../../assets/images/horseAndJockey/room.jpg"
              alt={'A standard double bed room'}
            />
          </div>
          <p style={{ paddingTop: '2rem' }}>
            There is no transport provided between The White Hart Inn to The
            Horse & Jockey, so a taxi (or sober friend) would need to be
            arranged in advance. There are no Ubers in the area so we recommend
            using one of the taxi services listed below.
          </p>
        </section>
        <hr />
        <section id="other-local-hotels" className="OtherHotels">
          <h2>Recommended Hotels</h2>
          <p>
            If you are interested in staying nearby, listed below are some local
            hotels. Please contact us if you would like any extra advice about
            hotels or transport.
          </p>
          <div className="OtherHotelsRow">
            {otherHotels.map(({ name, url, address, distance, duration }) => {
              return (
                <div key={name} className="OtherHotel">
                  <h3>{name}</h3>
                  <Link to={url} target="_base">
                    visit website
                  </Link>
                  <p>
                    {address.map((line, i) => (
                      <>
                        {i > 0 ? <br /> : null}
                        {line}
                      </>
                    ))}
                  </p>
                  <p>
                    {distance} miles away
                    <br />({duration} minute drive)
                  </p>
                </div>
              );
            })}
          </div>
        </section>
        <hr />
        <section id="recommended-taxis" className="Taxis">
          <h2>Recommended Taxis</h2>
          <p>
            Below is a list of some of the taxi services recommended by the
            venue, all taxis should be booked in advance.
          </p>
          <p>
            Amber Taxis:{' '}
            <Link className="goldText" to="tel:01773 836100">
              01773 836100
            </Link>
          </p>
          <p>
            Bryans Taxis:{' '}
            <Link className="goldText" to="tel:01773 603555">
              01773 603555
            </Link>
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default AccommodationPage;
